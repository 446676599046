import React from "react"
import { Link } from "react-navi"

export interface IApiGroupInfo {
    title: string
    id: string
    identifiers: string[]
    hideIdentifiers?: string[]
    description: React.ReactNode
    descriptionRight?: React.ReactNode
}

export const apiGroupsInfo: IApiGroupInfo[] = [
    {
        id: "gettingStarted",
        identifiers: ["gettingStarted"],
        title: "Getting Started",
        description: (
            <div>
                <h4>API Keys</h4>
                <p>
                    Calls to the Swiipe API require (with a few exception) an API key, which will authenticate the caller and
                    authorize whether the API key has the needed scope for the request.
                </p>
                <p>
                    To get an API key, log in to the Swiipe Portal (Sandbox:{" "}
                    <a target="_blank" rel="noreferrer" href="https://sandbox-portal.swiipe.com">
                        https://sandbox-portal.swiipe.com
                    </a>{" "}
                    or Production:{" "}
                    <a target="_blank" rel="noreferrer" href="https://portal.swiipe.com">
                        https://portal.swiipe.com
                    </a>
                    ) as a merchant. See e.g. step 1-3 in <Link href="/docs/woocommerceinstallationsguide">this guide</Link>.
                </p>
                <p>
                    If you are a delivery provider and only need to perform captures, refunds, and voids, then you can get an API
                    key with this limited scope by creating a new API key on the Swiipe Portal API key page (
                    <a target="_blank" rel="noreferrer" href="https://portal.swiipe.com/api_key">
                        https://portal.swiipe.com/api_key
                    </a>
                    ).
                </p>
                <h4>Date and time</h4>
                <p>
                    Date string parameters can be written in multiple formats. If no timezone is indicated in the format, then UTC
                    is assumed. If used as a query parameter, then remember to encode it (space and plus need encoding). Examples
                    of formats are:
                    <ul style={{ marginTop: 10 }}>
                        <li>2023-01-20T12:23:45Z</li>
                        <li>2023-01-20 12:23:45+2:00</li>
                        <li>2023-01-20 12:23:45-2:00</li>
                        <li>2023-01-20 12:23:45.123</li>
                        <li>2023-01-20</li>
                    </ul>
                </p>
            </div>
        ),
        descriptionRight: (
            <div>
                <h4>Requesting the API</h4>
                <p>
                    The base endpoint for the Swiipe API is https://sandbox-api.swiipe.com for the Sandbox environment and
                    https://api.swiipe.com for the Production environment.
                </p>
                <p>
                    To add the API key to a request, use the Authorization header:{" "}
                    <div>
                        <code>Authorization: Bearer MyApiKey</code>
                    </div>
                </p>
                <p>
                    So to e.g. get the clientIds for the webshops that a given API key gives access to, make the following
                    request:
                    <div>
                        <code>
                            GET https://api.swiipe.com/v1/accounts/me/configuration
                            <br />
                            Authorization: Bearer MyApiKey
                        </code>
                    </div>
                </p>
            </div>
        ),
    },
    {
        id: "users",
        identifiers: ["/v1/users"],
        title: "Users",
        description: (
            <>
                <p>
                    The user object is a conveniency object, that contains all the information about user needed to perform a
                    checkout.
                </p>
                <p>
                    Among other things, the user object contains a UserCore object, that contains the basic information about the
                    user.
                </p>
            </>
        ),
    },
    {
        id: "deliveryLocations",
        identifiers: ["/v1/deliveryLocations", "/v1/users/me/deliveryLocations"],
        title: "Delivery Locations",
        description: (
            <>
                <p>A delivery location is the object describing where a customer would like an order shippied.</p>
                <p>
                    It is used for remembering the shipping on an individual order, but it is also stored on a user, so that the
                    user can choose it in a future checkout, for speedy process.
                </p>
                <p>
                    The delivery location can both be an address if the shipping is for private or business delivery, but it can
                    also be a pickup point for a known provider.
                </p>
            </>
        ),
    },
    {
        id: "orders",
        identifiers: ["/v1/clients/{clientId}/orders", "/v1/orders"],
        title: "Orders",
        description: (
            <>
                <p>
                    In order to make a payment you first have to create an order. After the order has been created, you can use
                    the returned orderId to create a payment on the order.
                </p>
            </>
        ),
    },
    {
        id: "orderPayment",
        identifiers: ["/v1/payment", "/v1/orders/{id}/payment", "/v1/clients/{clientId}/payment"],
        title: "Payment for orders",
        description: <></>,
    },
    {
        id: "transactions",
        identifiers: ["/v1/payment", "/v1/transactions", "/v1/clients/{clientId}/transactions"],
        title: "Transactions",
        description: (
            <>
                <p>The life cycle of a payment for an order will consist of multiple transactions.</p>
                <p>
                    Creating a payment for a customer will create an auth transaction. If the first auth transaction fails, then
                    multiple tried will lead to multiple auth transactions.
                </p>
                <p>Subsequest voids, partial/full captures, and partial/full refunds will also create a transaction.</p>
            </>
        ),
    },
    {
        id: "configuration",
        identifiers: [
            "/v1/configuration",
            "/v1/accounts/me/configuration",
            "/v1/clients/{clientId}/configuration",
            "/v1/clients/public/configuration",
        ],
        title: "Configuration",
        description: <p>These endpoints relates to different configurations of the client, making the request.</p>,
    },
    {
        id: "status",
        identifiers: ["/v1/clients/{clientId}/status", "/v1/endpointStatus"],
        title: "Status",
        description: <p>Get various statuses of the Swiipe system</p>,
    },
    {
        id: "location",
        identifiers: ["/v1/location"],
        title: "Location",
        description: <></>,
    },
    {
        id: "report",
        identifiers: ["/v1/report", "/v1/accounts/me/report", "/v1/clients/{clientId}/report"],
        title: "Report",
        description: <p>Different ways of reporting the usage of APIs and plugins for platforms.</p>,
    },
    {
        id: "legal",
        identifiers: ["/v1/terms", "/v1/legal/documents"],
        hideIdentifiers: ["/v1/legal/documents"],
        title: "Legal",
        description: <p>Legal terms for different Swiipe services.</p>,
    },
    {
        id: "integrator",
        identifiers: ["/v1/integrator"],
        title: "Integrator",
        description: (
            <p>
                Enables the possibility to integrate with Swiipe in various ways, e.g. showing product recommendations outside of
                the webshop.
            </p>
        ),
    },
]
