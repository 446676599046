import { apiGroupsInfo, IApiGroupInfo } from "./../data/apiGroups"
import { hasValue, sortAscending, sortDescending } from "../util/arrayUtil"
import { IApiDoc, IApiDocPathItem, IApiDocRoute } from "./../type/api/apiDocTypes"

export interface IRouteGroup {
    routeWithDocList?: {
        route: IApiDocRoute
        docMethod: IApiDocPathItem
    }[]
    id: string
    info?: IApiGroupInfo
}

export function getResolvedGroupData(routes: IApiDocRoute[], docs: IApiDoc[]) {
    return sortGroups(addNonApiGroups(divideRoutesIntoGroups(routes, docs)))
}

function addNonApiGroups(currentGroups: IRouteGroup[]): IRouteGroup[] {
    return [
        ...currentGroups,
        ...apiGroupsInfo.filter((g) => !currentGroups.find((cg) => cg.info === g)).map((i) => ({ info: i, id: i.id })),
    ]
}

function sortGroups(groups: IRouteGroup[]) {
    return sortAscending(groups, (g) => {
        const index = apiGroupsInfo.findIndex((gi) => gi === g.info)
        return index === -1 ? 1000 : index
    })
}

function divideRoutesIntoGroups(routes: IApiDocRoute[], docs: IApiDoc[]) {
    const routesWithDocs = routes
        .map((r) => {
            const docPath = docs.find((d) => d.endpoint === r.docsJsonEndpoint)?.paths[r.pathTemplate]
            const docMethod = docPath && docPath[r.overrideExternalMethod || r.method]
            if (!docMethod) {
                return
            }
            return { route: r, docMethod }
        })
        .filter(hasValue)

    return routesWithDocs.reduce<IRouteGroup[]>((groups, item) => {
        const groupInfos = apiGroupsInfo.filter((gi) =>
            gi.identifiers.find((identifier) => item.route.pathTemplate.startsWith(identifier))
        )
        const sortedByLongestMatch = sortDescending(groupInfos, (gi) =>
            Math.max(...gi.identifiers.map((id) => (item.route.pathTemplate.startsWith(id) ? id.length : 0)))
        )

        const gi = sortedByLongestMatch.length > 0 ? sortedByLongestMatch[0] : undefined
        if (gi?.hideIdentifiers?.find((identifier) => item.route.pathTemplate.startsWith(identifier))) {
            // Hide route
            return groups
        }

        let group = groups.find((g) => g.info?.id === gi?.id ?? "other")
        if (!group) {
            group = {
                info: gi,
                id: gi?.id ?? "other",
                routeWithDocList: [],
            }
            groups.push(group)
        }
        ;(group.routeWithDocList ?? []).push({
            docMethod: item.docMethod,
            route: item.route,
        })

        return groups
    }, [])
}
